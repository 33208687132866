import Vue from "vue";
import Router from "vue-router";

const home = () =>
    import("@/components/pages/home");

const download = () =>
    import("@/components/pages/download");
const downloadmac = () =>
    import("@/components/pages/download-mac");
const downloadios = () =>
    import("@/components/pages/download-ios");
const downloadandroid = () =>
    import("@/components/pages/download-android");
const downloadlinux = () =>
    import("@/components/pages/download-linux");
const downloadandroidtv = () =>
    import("@/components/pages/download-android-tv");

const faq = () =>
    import("@/components/pages/faq");
const login = () =>
    import("@/components/pages/login");
const signup = () =>
    import("@/components/pages/signup");
const blogs = () =>
    import("@/components/pages/blogs/blogs");
const blogdetail = () =>
    import("@/components/pages/blogs/blogdetail");
const serverdetails = () =>
    import("@/components/pages/server-details");
const affiliateprogram = () =>
    import("@/components/pages/affiliate-program");
const ipaddresslookup = () =>
    import("@/components/pages/ip-address-lookup");
const myaccount = () =>
    import("@/components/account/my-account");
const devicemanagement = () =>
    import("@/components/account/device-management");
const changepassword = () =>
    import("@/components/account/change-password");

Vue.use(Router);

const frogrouter = new Router({
    routes: [{
        path: "/",
        name: "home",
        component: home
    },
    {
        path: "/download",
        name: "download",
        component: download,
    },
    {
        path: '/download/mac',
        name: 'downloadmac',
        component: downloadmac,
    },
    {
        path: '/download/ios',
        name: 'downloadios',
        component: downloadios,
    },
    {
        path: '/download/android',
        name: 'downloadandroid',
        component: downloadandroid,
    },
    {
        path: '/download/linux',
        name: 'downloadlinux',
        component: downloadlinux,
    },
    {
        path: '/download/android-tv',
        name: 'downloadandroidtv',
        component: downloadandroidtv,
    },
    {
        path: "/faq",
        name: "faq",
        component: faq
    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    {
        path: "/purchase",
        name: "signup",
        component: signup
    },
    {
        path: "/blogs",
        name: "blogs",
        component: blogs
    },
    {
        path: "/blogs/:id",
        name: "blogdetail",
        component: blogdetail
    },
    {
        path: "/server-details",
        name: "serverdetails",
        component: serverdetails
    },
    {
        path: "/affiliate-program",
        name: "affiliateprogram",
        component: affiliateprogram
    },
    {
        path: "/ip-address-lookup",
        name: "ipaddresslookup",
        component: ipaddresslookup
    },
    {
        path: "/my-account",
        name: "myaccount",
        component: myaccount
    },
    {
        path: "/device-management",
        name: "devicemanagement",
        component: devicemanagement
    },
    {
        path: "/change-password",
        name: "changepassword",
        component: changepassword
    },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode: "history"
});

// frogrouter.beforeEach(function (to, from, next) {    
//     next();
// });

// frogrouter.afterEach((to, from, failure) => {

// });

export default frogrouter;