<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
Vue.mixin({
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
});

export default {
  name: "App",
};
</script>

<style>
#nprogress .bar {
  background: var(--primarycolor) !important;
  padding: 0 10px !important;
  height: 2px !important;
}

#nprogress .spinner .spinner-icon {
  border-top-color: var(--primarycolor) !important;
  border-left-color: var(--primarycolor) !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
